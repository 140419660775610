import React, { useState,useEffect } from "react"
import Logo from "./Logo"
import styled from 'styled-components'

const Header = styled.div`
width: 100%;
background-color: #ffffff;
`
const Navigation = styled.nav`
  height: 14vh;
  max-width: 1050px;
  text-align: center;
  background-color: #ffffff;
  position: relative;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 10px auto;
  padding: 0 1em;
  z-index: 2;
  align-self: center;
  img {
    max-width: 280px;
  }

  @media (max-width: 900px) {
    position:  ${Navbox => (Navbox.open ? "absolute" : "sticky")};
    height: 90px;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    padding: 0 5vw;
  }
`
const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 2vw;
  @media (max-width: 900px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: rgba(13,13,13,.88);
    transition: all 0.3s ease-in;
    top: 55px;
    right: ${props => (props.open ? "-100%" : "0")};
    overflow-y: scroll;
  }
`
const Hamburger = styled.div`
  background-color: #000000;
  width: 22px;
  height: 2px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 22px;
    height: 2px;
    background-color: #000000;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-7px, 0px)" : "rotate(0deg)"};
    top: -7px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 7px;
  }
`

export default function  Navbar() {
  useEffect(() => {
    if(navbarOpen == true) {
      document.body.style.overflow = 'hidden';
    }
    else {document.body.style.overflow = 'auto';}
  });
    const [navbarOpen, setNavbarOpen] = useState(false)
    return (
      <Header>
      <Navigation id="sticky-wrapper" className="sticky-wrapper">
        <center><Logo /></center>
      </Navigation>
      </Header>
    )
  }
  
