import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { globalStyles } from '../styles/globalStyles.js'
import Navbar from "../components/Navbar/Navbar"

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <Root className="siteRoot">
      <div className="siteContent">
        <Navbar />
        <div id="main">{props.children}</div>
      </div>
      <Footer />
      <Global styles={globalStyles} />
    </Root>
  )
}

export default Layout
