import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
    <div class="suodatin2">
    <center><p><b>Vastuullista pelaamista - K18</b></p></center>
<p>Aseta itsellesi pelibudjetti ja pysy siinä. Pelaa kasinopelejä vain pitäkseesi hauskaa, älä tienataksesi rahaa.</p>
<p>Kasinoilla on tarjolla useita työkaluja ja omaehtoisia rajoittimia jotka auttavat sinua pitämään pelaamista hallinnassa.</p>
<p>Tilanteen karatessa pahaksi, voit jäädyttää asiakastilisi valitsemaksesi ajaksi.</p>
</div>
      </Item>
      <Item>
      </Item>
      <Item>
      </Item>
      <Item>
      <center><img
            className='lazyload'
            data-src="/images/saannot-ehdot.webp"
            style={{ width: '240px' }}
            alt="Vastuullinen pelaaminen"
          /></center>
      <center><p>contact@kasino2025.com</p></center>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
